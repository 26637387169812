<template>
  <div class="body">
    <dashboard-header></dashboard-header>
    <main>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="form_control section___">
                <signup-form></signup-form>
              </div>
            </div>
            <div class="col-md-7">
              <div class="section_scroll">
                <div class="header_banner section___">
                  <div class="count_">100 <span>pts</span></div>
                  <div class="header____">
                    <h3>نقاطي</h3>
                    <p>
                      احصل على ٢٠٠ نقطة مع كل طلب تبرع ليمكنك استبدال النقاط
                      بعروض وخصومات من شركاءنا
                    </p>
                  </div>
                </div>

                <div class="section_card">
                  <h4>خصوماتي</h4>
                  <div class="section_inner">
                    <div class="item_list">
                      <div class="item_card">
                        <img src="@/assets/img/img-2.jpg" alt="" />
                        <p>15% OFF</p>
                        <p>Rana Bakery</p>
                        <span>50 <small>pts</small></span>
                      </div>
                      <div class="item_card">
                        <img src="@/assets/img/img-2.jpg" alt="" />
                        <p>15% OFF</p>
                        <p>Rana Bakery</p>
                        <span>50 <small>pts</small></span>
                      </div>
                      <div class="item_card">
                        <img src="@/assets/img/img-2.jpg" alt="" />
                        <p>15% OFF</p>
                        <p>Jumpup Arena working fine</p>
                        <span>50 <small>pts</small></span>
                      </div>
                      <div class="item_card">
                        <img src="@/assets/img/img-2.jpg" alt="" />
                        <p>15% OFF</p>
                        <p>Rana Bakery</p>
                        <span>50 <small>pts</small></span>
                      </div>
                    </div>
                    <button class="btn btn-lined btn-block">اظهر المزيد</button>
                  </div>
                </div>
                <div class="section_card detail_section">
                  <h4>طلباتي</h4>

                  <div class="track_c_progress">
                    <div class="top_section">
                      <div class="infor_section">
                        <p>Your Request <b>No. 7686656</b></p>
                        <p><b>Home |</b> As Salamah District. Jedd….</p>
                      </div>
                      <div class="call_section">
                        <span>Call agent</span>
                        <span class="call">
                          <svg
                            width="17px"
                            height="18px"
                            viewBox="0 0 17 18"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                          >
                            <g
                              id="Symbols"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g
                                id="help_icon-copy"
                                transform="translate(-6.000000, -5.000000)"
                                stroke="#8E8E8E"
                              >
                                <g
                                  id="Group-5"
                                  transform="translate(6.000000, 6.000000)"
                                >
                                  <g
                                    id="Group-4-Copy"
                                    transform="translate(0.000000, 0.255060)"
                                  >
                                    <path
                                      d="M8.5,14.45 C10.59365,14.45 12.3364211,14.360565 13.8185659,13.1069823 C15.043679,12.0707944 15.4545455,9.24269394 15.4545455,7.49545455 C15.4545455,3.65456515 12.3408894,0.540909091 8.5,0.540909091 C4.6591106,0.540909091 1.54545455,3.65456515 1.54545455,7.49545455"
                                      id="Oval-3"
                                      stroke-width="1.66"
                                    ></path>
                                    <rect
                                      id="Rectangle"
                                      fill="#8E8E8E"
                                      x="0.886363636"
                                      y="6.81312145"
                                      width="3.63636364"
                                      height="4.40909091"
                                      rx="1.81818182"
                                    ></rect>
                                    <rect
                                      id="Rectangle-Copy"
                                      fill="#8E8E8E"
                                      x="12.4773581"
                                      y="6.81312145"
                                      width="3.63636364"
                                      height="4.40909091"
                                      rx="1.81818182"
                                    ></rect>
                                    <path
                                      d="M9.27272727,12.9949396 C8.48195593,12.9949396 7.84090909,13.6359865 7.84090909,14.4267578 C7.84090909,15.2175292 8.48195593,15.858576 9.27272727,15.858576 C10.0634986,15.858576 10.7045455,15.2175292 10.7045455,14.4267578 C10.7045455,13.6359865 10.0634986,12.9949396 9.27272727,12.9949396 Z"
                                      id="Rectangle-Copy"
                                      fill="#8E8E8E"
                                      transform="translate(9.272727, 14.426758) rotate(90.000000) translate(-9.272727, -14.426758) "
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div class="progress_">
                      <div class="progress_line">
                        <span class="stage done">
                          <span class="active">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.9979 0.887183L4.26693 11.1945L3.45269 11.1576L0 6.24127L0.843095 5.65023L3.91266 10.0197L12.2122 0.220703L13 0.887183H12.9979Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </span>

                        <div class="v_infor">
                          Request sent
                          <span class="time">5 minutes ago</span>
                        </div>
                      </div>
                      <div class="progress_line">
                        <span class="stage success">
                          <span class="active">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.9979 0.887183L4.26693 11.1945L3.45269 11.1576L0 6.24127L0.843095 5.65023L3.91266 10.0197L12.2122 0.220703L13 0.887183H12.9979Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </span>
                        Agent assigned
                      </div>
                      <div class="progress_line">
                        <span class="stage">
                          <span class="active">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.9979 0.887183L4.26693 11.1945L3.45269 11.1576L0 6.24127L0.843095 5.65023L3.91266 10.0197L12.2122 0.220703L13 0.887183H12.9979Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </span>
                        On the way for pick up
                      </div>
                      <div class="progress_line">
                        <span class="stage">
                          <span class="active">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.9979 0.887183L4.26693 11.1945L3.45269 11.1576L0 6.24127L0.843095 5.65023L3.91266 10.0197L12.2122 0.220703L13 0.887183H12.9979Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </span>
                        Completes
                      </div>
                    </div>
                  </div>

                  <div class="track_c_progress">
                    <div class="top_section">
                      <div class="infor_section">
                        <p>Your Request <b>No. 7686656</b></p>
                        <p><b>Home |</b> As Salamah District. Jedd….</p>
                      </div>
                      <div class="call_section">
                        <div class="green_">+100 <small>pts</small></div>
                      </div>
                    </div>
                    <div class="top_section">
                      <div class="checker">
                        <label for="completed">
                          <span class="dot"></span>
                          Completed
                        </label>
                      </div>
                      <p>11/03/2020 16:31:55</p>
                    </div>
                  </div>

                  <div class="section_inner">
                    <button class="btn btn-lined btn-block">اظهر المزيد</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import DashboardHeader from "../../components/DashboardHeader.vue";
import SignupForm from "../../components/SignupForm.vue";

export default {
  components: { DashboardHeader, SignupForm },
};
</script>

<style lang="scss" scoped>
.form_control {
  z-index: 1;
  position: relative;
  .form_wrapp {
    top: 0;
  }
}
.section___ {
  margin-top: 3em;
}
</style>